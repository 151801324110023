export default function getLoginRedirect(permissions) {
	if (permissions.team.name == "worker") {
		//onboarding if required
		const inOnboarding = window.location.href.includes("onboarding");
		if (!inOnboarding && permissions.userStatus.includes("pending"))
			return "/onboarding";

		//disabled users to settings
		if (permissions.userStatus.includes("disabled"))
			return { name: "MyUserProfileSettings" };
	}
	return "/bookings";
}
