import { defineStore } from "pinia";
import { connect as socketIoConnect } from "../utils/socketIo";
import { useRoute, useRouter } from "vue-router";
import axios from "axios";

export const useChatStore = defineStore("chatStore", {
	state: () => {
		//if url param to open chat handle it
		const route = useRoute();
		const router = useRouter();
		const handleOpenChatQueryParam = to => {
			if (to.query?.openChat)
				setTimeout(() => {
					const store = useChatStore();
					store.openChat(to.query?.openChat);
					const query = Object.assign({}, route.query);
					delete query.openChat;
					router.replace({ query });
				});
		};
		router.afterEach(handleOpenChatQueryParam);
		handleOpenChatQueryParam(route);

		return {
			// show: window.innerWidth > 575,
			show: false,
			chats: [],
			totalChats: null,
			openChats: [],
			maxOpenChats: 1,
			socket: null,
			socketConnected: false,
		};
	},
	actions: {
		async openChat(chat, message) {
			if (!this.show) this.showChats();

			//check have full chat indentification
			if (typeof chat == "string")
				chat = await axios
					.get(`api/v1/chats/${chat}/identification`)
					.then(res => res.data.identification);

			//check not open
			const openChat = this.openChats.find(
				({ chat_id }) => chat_id == chat.chat_id,
			);
			if (openChat) {
				//maximize it
				const component = await openChat.component;
				component.maximize();
				component.setMessage(message);
				return;
			}

			//open
			const fullOpenChat = {
				...chat,
				//allow component to be attached using promise
				_component: null,
				get component() {
					if (this._component)
						return Promise.resolve(this._component);
					this._component = new Promise(res => {
						this.setComponent = compRef => {
							delete this.setComponent;
							this._component = compRef;
							res(compRef);
						};
					});
					return this._component;
				},
			};
			this.openChats.unshift(fullOpenChat);
			if (message)
				fullOpenChat.component.then(component => {
					component.setMessage(message);
				});

			this.enforceMaxOpenChats();
		},
		closeChat(chat_id) {
			const index = this.openChats.findIndex(c => c.chat_id == chat_id);
			if (index > -1) this.openChats.splice(index, 1);
		},
		enforceMaxOpenChats() {
			while (this.openChats.length > this.maxOpenChats)
				this.openChats.pop();
		},
		setMaxOpenChats(windowWidth) {
			this.maxOpenChats =
				(windowWidth > 1310 ? 3 : windowWidth > 1010 ? 2 : 1) || 1;
		},
		showChats() {
			this.show = true;
		},
		closeChats() {
			this.show = false;
			this.openChats.splice(0);
		},
		clearChatsList() {
			this.openChats.splice(0);
			this.chats.splice(0);
		},
		updateChatList(newEntry) {
			const existing = this.chats.find(
				c => c.chat_id == newEntry.chat_id,
			);
			if (!existing) this.chats.unshift(newEntry);
			else Object.assign(existing, newEntry);

			if (!existing && this.totalChats !== null) this.totalChats++;
		},
		setupUser(user_id) {
			this.socket = socketIoConnect("chats");

			this.socket.on("connect", () => {
				//mark connected
				this.socketConnected = true;

				//if recovered do nothing (socket io handles automatically)
				if (this.socket.recovered) return;

				//subscribe to users room and process the chat list updates
				this.socket.emit("subscribe", [`user:${user_id}`], ack => {
					if (ack.chatList?.items)
						ack.chatList.items.forEach(this.updateChatList);
				});

				//subscribe to any open chats
				this.socket.emit(
					"subscribe",
					this.openChats.map(({ chat_id }) => `chat:${chat_id}`),
				);
			});

			//handle any chat list updates
			this.socket.on("update-chat-list", this.updateChatList);
		},
		clearUser() {
			if (this.socket) {
				this.socket.emit("unsubscribe", "*");
				this.clearChatsList();
				this.socket.disconnect();
				this.socket = null;
				this.socketConnected = false;
			}
		},
	},
	getters: {
		totalUnread() {
			return this.chats.reduce((t, c) => t + (c.unread_messages ?? 0), 0);
		},
	},
});
