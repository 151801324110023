import { getToken } from "@/auth";
const { io } = require("socket.io-client");

//connect to given namespace using token and reconnect with new token if fails
export function connect(namespace) {
	const token = getToken();
	const socket = io(process.env.VUE_APP_BACKEND_URL_LOCAL + "/" + namespace, {
		auth: {
			token,
		},
	});

	//update token on error
	socket.on("connect_error", () => {
		socket.auth.token = getToken();
		if (socket.auth.token) socket.connect();
	});

	return socket;
}
